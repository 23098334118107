/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



function blokkenheight() {
  $('.section').each(function(){
  
    $('.list-contact .label',this).width('auto');

    //if ($(window).width() > 767) {
      var highestBox = 0;
      $('.list-contact .label', this).each(function(){

          if($(this).width() > highestBox) {
             highestBox = $(this).width(); 
           }
      });  

      $('.list-contact .label',this).width(highestBox);
    //}

  });
}
blokkenheight();

/*
WebFont.load({
  google: {
    families: ['Open+Sans:300,600,700']
  },
  active: function () { blokkenheight(); }
});
*/

$(window).resize(function() {
  blokkenheight();
});

$(document).on('click', 'a[href^="#"]:not(.collapse-btn)', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

$('.slider-header').slick({
  arrows: false,
});

$('.slider-voertuig').each(function( index ) {
  $(this).attr('data-slider', index);

  $('.slider', this).slick({
    appendArrows: '[data-slider="' + index + '"] .slider-controls',
    appendDots:  '[data-slider="' + index + '"] .slider-controls',
    nextArrow: '<span class="slick-arrow arrow-right"><i class="fas fa-arrow-right"></i></span>',
    prevArrow: '<span class="slick-arrow arrow-left"><i class="fas fa-arrow-left"></i></span>',
    arrows: true,
    dots:   false,
    focusOnSelect: true,
    autoplay: true,
  });
});
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        AOS.init({
          offset: 100, // offset (in px) from the original trigger point
          easing: 'ease-in-out', // default easing for AOS animations
          mirror: true, // whether elements should animate out while scrolling past them
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
